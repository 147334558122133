import React, { useState, useRef } from "react";
import axios from "axios";

export default function SearchPhotos() {
  const [prompt, setPrompt] = useState("");
  const [generatedImage, setGeneratedImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let  generatedImageURL = "";

  const generateImage = async (e) => {
    debugger
    e.preventDefault();
    debugger
    const data = {
      prompt: prompt,
      n: 1,
      size: "512x512",
      response_format:"url",
    };
    const config = {
      method: "post",
      url: "https://api.openai.com/v1/images/generations", // Place Your API URL
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer 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", //Change the secert key after prefix Bearer
      },
      data: data,
    };
    setIsLoading(true);

    try {
      const response = await axios(config);
      localStorage.setItem('imageURL',  response.data.data[0].url);
      generatedImageURL = response.data.data[0].url;
      setGeneratedImage(response.data.data[0].url);
      return response.data.data[0].url;
    } catch (error) {
      console.log("Error generating image:", error.response.data);
    } finally {
      setIsLoading(false);
    }
  };


function handleDownload() {
  const storedImageUrl = localStorage.getItem('imageURL');

  const link = document.createElement('a');
  link.href = storedImageUrl;
  link.download = 'image.png';
  link.target = '_blank';
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}



  return (
    <>
      <div className="main-form-control">
        <form className="form" onSubmit={generateImage}>
          <label className="label" htmlFor="query">
            📷
          </label>
          <input
            type="text"
            name="query"
            className="input"
            placeholder={`Try "camera" or "basket"`}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
          <button type="submit" className="custom-btn btn-12">
            <span>Click!</span>
            <span>Search</span>
          </button>
        </form>
        {isLoading ? (
          <div className="spinner-overlay">
            <div className="spinner"></div>
          </div>
        ) : generatedImage ? (
          <>
            <div className="generated-image">
              <img id="generatedImage" src={generatedImage} alt="Generated" />
            </div>
            <div className="downloadBtn">
              <button className="custom-btn btn-12" onClick={handleDownload}>
                Download
              </button>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}
