import React, { useEffect } from 'react';
import cvLogo from '../../assets/cv-logo.svg';
import cvFIcon from '../../assets/facebook.svg';
import cvTIcon from '../../assets/twitter.svg';
import cvLIcon from '../../assets/linkedIn.svg';

function Navigation(props) {
  useEffect(() => {
    const handleLogoClick = () => {
      window.location.reload();
    };

    const logoElement = document.getElementById('cvLogo');
    logoElement.addEventListener('click', handleLogoClick);

    return () => {
      logoElement.removeEventListener('click', handleLogoClick);
    };
  }, []);

  return (
    <div className='mainNavContainer'>
      <header className="site-header">
        <div className="site-identity">
          <a  id="cvLogo">
            <img src={cvLogo} alt="Site Name" />
          </a>
        </div>
        <nav className="site-navigation">
          <ul className="nav">
            <li><a href="https://www.facebook.com/codevisiontechnologies/"><img src={cvFIcon} alt="Site Name" /></a></li>
            <li><a href="https://twitter.com/i/flow/login?redirect_after_login=%2FCOD3VISION%2F"><img src={cvTIcon} alt="Site Name" /></a></li>
            <li><a href="https://www.linkedin.com/company/codevision-technologies/"><img src={cvLIcon} alt="Site Name" /></a></li>
          </ul>
        </nav>
      </header>
    </div>
  );
}

export default Navigation;
